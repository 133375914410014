import { encode as encodeToBase64 } from 'base64-arraybuffer';
import { addDays, format as dateFormat } from 'date-fns';
import bus from '$bus';
import _ from 'lodash';

const wslog = import.meta.env.VITE_APP_WS_LOG
  ? (() => {
    const socket = new WebSocket(`ws://${window.location.hostname}:9111`);
    function wslog(...args: any[]) {
      socket.send(Array.from(args).map((i) => JSON.stringify(i)).join(' '));
    }
    return wslog;
  })()
  : () => {};

function timestampFromArtifactId(id: string) {
  const year = parseInt(id.substring(0, 4), 10);
  const month = parseInt(id.substring(4, 6), 10);
  const day = parseInt(id.substring(6, 8), 10);
  const hour = parseInt(id.substring(8, 10), 10);
  const minute = parseInt(id.substring(10, 12), 10);
  const second = parseInt(id.substring(12, 14), 10);
  return Date.UTC(year, month - 1, day, hour, minute, second) / 1000;
}

function deviceNumFromArtifactId(id: string) {
  return parseInt(id.substr(id.length - 8), 10);
}

function flatten(obj: object, key: string) {
  let output = {};
  for (const i in obj) {
    if (!Reflect.ownKeys(obj).length) continue;
    output = {
      ...output,
      ...((obj[i] && typeof obj[i] === 'object' ? flatten(obj[i], i) : {
        [key ? `${key}_${i}` : i]: obj[i],
      })),
    };
  }
  return output;
}

function coordToLatLng(coord: any) {
  const lat = coord.lat || coord.latitude || 0;
  const lng = coord.lon || coord.lng || coord.longitude || 0;
  return new window.google.maps.LatLng(lat, lng);
}

const base64ToBlob = (dataURI) => {
  const byteString = window.atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
};

export const routeConfig = (name: string, meta: any, component: any, options: any) => {
  const path = name
    .replace(/(?!^)([A-Z])/g, (match: any) => `-${match}`)
    .toLowerCase();
  return {
    path: `/${path}`,
    name,
    component,
    meta: _.assign(
      {
        auth: true,
        topRow: true,
      },
      meta,
    ),
    ...options,
  };
};

const hex = (c: any) => {
  let s = "0123456789abcdef";
  let i = parseInt(c);
  if (i == 0 || isNaN(c))
      return "00";
  i = Math.round (Math.min (Math.max (0, i), 255));
  return s.charAt ((i - i % 16) / 16) + s.charAt (i % 16);
};

/* Convert an RGB triplet to a hex string */
const convertToHex = (rgb: any) => {
  return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);
};

/* Remove '#' in color hex string */
const trimHexColor = (s: any) => {
  return (s.charAt(0) == '#') ? s.substring(1, 7) : s;
};

const convertToRGB = (hex: any) => {
  let color = [];
  color[0] = parseInt((trimHexColor(hex)).substring (0, 2), 16);
  color[1] = parseInt((trimHexColor(hex)).substring (2, 4), 16);
  color[2] = parseInt((trimHexColor(hex)).substring (4, 6), 16);
  return color;
};

const generateColor = (colorStart: string, colorEnd: string, colorCount: number) => {
  // The beginning of your gradient
  let start = convertToRGB (colorStart);    
  // The end of your gradient
  let end = convertToRGB (colorEnd);    
  // The number of colors to compute
  let len = colorCount;
  //Alpha blending amount
  let alpha = 0.0;
  let saida = [];
  for (let i = 0; i < len; i++) {
      let c = [];
      alpha += (1.0/len);
      c[0] = start[0] * alpha + (1 - alpha) * end[0];
      c[1] = start[1] * alpha + (1 - alpha) * end[1];
      c[2] = start[2] * alpha + (1 - alpha) * end[2];
      saida.push(convertToHex (c));
  }
  return saida;
};

const isDarkMode = () => {
  if (
    window.localStorage.theme === 'dark'
    || (
        !('theme' in window.localStorage)
        && window.matchMedia('(prefers-color-scheme: dark)').matches
    )
  ) return true;
  return false;
}
const toggleDarkMode = (appearance: string) => {
  if (appearance === 'dark') localStorage.theme = 'dark';
  if (appearance === 'light') localStorage.theme = 'light';
  if (appearance === 'system-preference') localStorage.removeItem('theme');
  // On page load or when changing themes, best to add inline in `head` to avoid FOUC
  const result = isDarkMode();
  bus.emit('appearance-changed', result);
  if (result) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
};

export default {
  isDarkMode,
  toggleDarkMode,
  generateColor,
  hashParam(name: any) {
    const match = window.location.hash.match(new RegExp(`${name}=([^&]*)`));
    if (!match) return null;
    return match[1];
  },
  hashMagic() {
    if (window.location.hash.includes('=')) {
      return null;
    }
    const match = window.location.hash.match(new RegExp('\\?(.*)'));
    if (!match) return null;
    return match[1];
  },
  formatRecStatus(v: String) {
    if (v === 'merged' || v === 'stored') return 'Downloaded/Ready';
    if (v === 'deleted') return 'Deleted';
    if (v === 'in-car') return 'In Car';
    // stored, in-car, queued
    return 'Processing';
  },
  base64ToBlob,
  dateOnlyFromDate(date: number | Date) {
    return dateFormat(date, 'yyyy-MM-dd');
  },
  dateOnlyFromTimestamp(timestamp: number) {
    if (!timestamp) return '';
    return dateFormat(new Date(timestamp * 1000), 'yyyy-MM-dd');
  },
  shortDateFromTimestamp(timestamp: number) {
    if (!timestamp) return '';
    return dateFormat(new Date(timestamp * 1000), 'MMM d HH:mm').replace(/ /g, '\xa0');
  },
  dateFromTimestamp(timestamp: number) {
    if (!timestamp) return '';
    return dateFormat(new Date(timestamp * 1000), 'yyyy-MM-dd HH:mm:ss').replace(' ', '\xa0');
  },
  timeFromTimestamp(timestamp: number) {
    if (!timestamp) return '';
    return dateFormat(new Date(timestamp * 1000), 'HH:mm:ss');
  },
  daysBetween(since: string | number | Date, until: number) {
    let i = new Date(since);
    const r = [];
    while (i <= until) {
      r.push(i);
      i = addDays(i, 1);
    }
    return r;
  },
  ordinalSuffixOf: (i: number) => {
    const j = i % 10;
    const k = i % 100;
    if (j == 1 && k != 11) return `${i}st`;
    if (j == 2 && k != 12) return `${i}nd`;
    if (j == 3 && k != 13) return `${i}rd`;
    return `${i}th`;
  },
  secondsToDuration(seconds: number) {
    let result = '';
    const hours = Math.floor(seconds / 3600);
    if (hours) result = `${hours} hr`;
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    if (hours) result += ' ';
    result += `${minutes} min`;
    if (!hours && !minutes) {
      const s = Math.floor(seconds);
      if (s) {
        result = `${s} sec`;
      } else {
        result = '0';
      }
    }
    return result;
  },
  secondsToShortDuration(seconds: number) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);
    let result = '';
    if (h) {
      result += String(h).padStart(2, '0');
      result += ':';
    }
    result += String(m).padStart(2, '0');
    result += ':';
    result += String(s).padStart(2, '0');
    return result;
  },
  timestampFromArtifactId,
  distance(meters: number, store: { state: { user: { prefs: { [x: string]: any; }; }; }; }, precision = 1) {
    if (!store.state.user) return '';
    let value = meters / 1000;
    let unit = 'km';
    if (store.state.user.prefs['use-imperial']) {
      value = meters / 1609.344;
      unit = 'mi';
    }
    // show precision if the value was lower than 1000
    value = value.toFixed(value < 1000 ? precision : 0);
    return `${parseFloat(value).toLocaleString('en')}\xa0${unit}`;
  },
  speed(speed: number, store: { state: { user: { prefs: { [x: string]: any; }; }; }; }) {
    if (!store.state.user || !_.isNumber(speed)) return '-';
    if (store.state.user.prefs['use-imperial']) {
      return `${Math.floor(speed / 1.609)}\xa0mph`;
    }
    return `${Math.floor(speed)}\xa0km/hr`;
  },
  jpgToImgSrc(arrayBuffer: ArrayBuffer) {
    return `data:image/jpg;base64, ${encodeToBase64(arrayBuffer)}`;
  },
  deviceNumFromArtifactId,
  recsAreRelated(idA: any, idB: any) {
    if (deviceNumFromArtifactId(idA) !== deviceNumFromArtifactId(idB)) return false;
    return Math.abs(timestampFromArtifactId(idA) - timestampFromArtifactId(idB)) < 25;
  },
  isDeviceBeforeRevD(deviceId: string) {
    // all ids like nd-* and
    // all ids with 3 digit that start with 0,1 and 2
    return /^(nd-\d+|nn-[012]\d{2})$/gi.test(deviceId);
  },
  recStatusDescription(status: string) {
    if (status === 'merged') return 'Ready';
    if (status === 'stored') return 'Downloaded Raw & Merging';
    return _.capitalize(status);
  },
  coordToLatLng,
  addOffsetCoord(coord: { lat: number; lon: number; }, dx: number, dy: number) {
    const earthRadius = 6371 * 1000;
    const lat = coord.lat
      + (dy / earthRadius)
      * (180 / Math.PI);
    const lon = coord.lon
      + (dx / earthRadius)
      * (180 / Math.PI) / Math.cos(coord.lat * Math.PI / 180);
    return coordToLatLng({ lat, lon });
  },
  msgViaProp(prop: { msg: { kind: any; }; }, kind: any) {
    prop.msg = { kind };
  },
  object: {
    add(a: { [x: string]: any; }, b: { [x: string]: any; }) {
      Object.keys(b).forEach((i) => { a[i] = (a[i] || 0) + b[i]; });
      return a;
    },
    scale(a: { [x: string]: number; }, s: number) {
      Object.keys(a).forEach((i) => { a[i] *= s; });
      return a;
    },
  },
  exportDataToCSV(data: any[], name: any) {
    const output = data.map((i: { map: (arg0: (j: any) => string) => { (): any; new(): any; toString: { (): any; new(): any; }; }; }) => i.map((j: any) => `"${j}"`).toString()).join('\n');
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(output)}`);
    link.setAttribute('download', `${name}.csv`);
    link.click();
  },
  flatten,
  deviceIsLive(device: { server_time: number; latest_contact_at: number; }) {
    let now = device?.server_time || (Date.now() / 1000);
    return now - device.latest_contact_at < 10;
  },
  routeConfig,
  redirect(store: { state: { authenticated: null; route: { path: any; meta: any; query: any; }; user: { role_name: any; has_fleets: any; }; reservedRoute: any; magic: any; }; commit: (arg0: string, arg1: null) => void; getters: { isRegularUser: any; showAnnotator: any; showAdmin: any; isInstallerUser: any; }; }) {
    const { path, meta, query } = store.state.route;
    if (query?.origin) {
      store.commit('setOriginRedirect', query?.origin);
    }
    if (query?.redirect) {
      store.commit('setRedirect', query?.redirect);
    }
    if (store.state.authenticated === null) return false;
    const excludes = meta.excludes ?? [];
    const res = (() => {
      // authed redirects
      if (store.state.authenticated) {
        // protect privileged views
        const roleName = _.kebabCase(store.state.user?.role_name ?? '');
        if (roleName === 'ap-reviewer') return '/login';
        if (excludes.some((i: any) => roleName === i)) return '/';
        if (store.state.reservedRoute) {
          const reservedRoute = _.clone(store.state.reservedRoute);
          store.commit('setReservedRoute', null);
          return reservedRoute;
        }
        // no fleets
        if (!store.state.user.has_fleets && store.getters.isRegularUser) {
          return '/unassigned';
        }
        // regular redirections
        let home = '/map/v2';
        if (store.getters.showAnnotator && !store.getters.showAdmin) {
          home = '/annotation';
        }
        if (store.getters.isInstallerUser) {
          home = '/profile/user-details';
        }
        if (store.getters.isDriverUser) {
          home = '/map';
        }
        const redirects = {
          '/': home,
          '/login': home,
          '/signup': home,
          '/mobile/login': '/mobile/share',
        };
        return redirects[path];
      }
      // unauthed redirects
      if (path === '/') {
        store.commit('setReservedRoute', path);
        return '/login';
      }
      if (meta.auth && !store.state.magic) {
        store.commit('setReservedRoute', path);
        if (path.startsWith('/install')) return '/login';
        if (_.startsWith(path, '/mobile')) return '/mobile/login';
        return '/login';
      }
      return undefined;
    })();
    if (res === path) return undefined; // prevent infinite loop
    return res;
  },
  setActiveInterval(func: () => any, delay: number | undefined, store: { getters: { showAdmin: any; }; state: { active: any; }; }, page: any) {
    let running = false;
    return setInterval(
      async () => {
        if (running) {
          console.warn('active interval skipping, still running'); // eslint-disable-line no-console
          return;
        }
        if (store.getters.showAdmin && page && window.intervalLogs) {
          console.log(`'${page}' page interval is running.`); // eslint-disable-line no-console
        }
        if (!store.state.active) return;
        running = true;
        try {
          await func();
        } catch (e) {
          console.error(e); // eslint-disable-line no-console
        }
        running = false;
      },
      delay,
    );
  },
  deviceDesc(obj: { name: any; device_name: any; device_id: any; id: any; }, store: { getters: { showHeadAnnotator: any; }; }) {
    const name = obj.name || obj.device_name;
    const id = obj.device_id || obj.id;
    if (!name) return id;
    if (store && store.getters.showHeadAnnotator) return `${name} 😇${id}`;
    return name;
  },
  incident: {
    unhidden(incidents: any) {
      return _.pickBy(incidents, (v: { hidden: any; }) => !v.hidden);
    },
  },
  magic: {
    kindDesc(kind: string | number) {
      return {
        device: 'Map and Snap',
        'fast-snaps': 'Camera Alignment',
        'incident-video': 'Incident Video',
      }[kind];
    },
  },
  masks: {
    email: {
      mask: 'Z*@Z*.Z*',
      tokens: {
        Z: { pattern: /[A-Za-z0-9_\-.+]/ },
      },
    },
    deviceId: {
      mask: ['###', '####'],
    },
    artifactId: {
      mask: '######################',
    },
    phone_number: {
      mask: '+# (###) ###-####',
    },
  },
  addGoogleChartsLoaderIfNotExist() {
    const gstaticSrc = 'https://www.gstatic.com/charts/loader.js';
    if (!document.querySelector(`script[src="${gstaticSrc}"]`)) {
      const script = document.createElement('script');
      script.src = gstaticSrc;
      script.type = 'text/javascript';
      document.querySelector('head').appendChild(script);
    }
  },
  fixAxiosErrors(ax: { interceptors: { request: { use: (arg0: (config: any) => any) => void; }; response: { use: (arg0: undefined, arg1: (error: any) => never) => void; }; }; }) {
    ax.interceptors.request.use((config: { errorContext: Error; }) => {
      config.errorContext = new Error('Thrown at:');
      return config;
    });
    ax.interceptors.response.use(undefined, (error: { stack: string; }) => {
      const originalStackTrace = _.get(error, 'config.errorContext.stack');
      if (originalStackTrace) {
        error.stack = `${error.stack}\n${originalStackTrace}`;
      }
      throw error;
    });
    return ax;
  },
  repr(x: string | null | undefined, options = {}) {
    if (x === undefined) return options.undefined || '⛔';
    if (x === null) return options.null || '0️⃣';
    if (x === '') return options.empty || '🔳';
    return x;
  },
  wslog,
};
