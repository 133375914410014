import {ax} from './helpers';

interface GetTrip {
  trip_id: String;
};
const getTripV3 = async (payload: GetTrip) => {
  const res = await ax.get(`/v3/trip/${payload.trip_id}`).catch((e) => e.response);
  if (res.status !== 200) return [];
  return res.data;
};

interface GetTripRoute {
  trip_id: String;
};
const getTripRoute = async (payload: GetTripRoute) => {
  const res = await ax.get(`/v3/trip/${payload.trip_id}/route`).catch((e) => e.response);
  if (res.status !== 200) return [];
  return res.data;
};

interface GetTripRecs {
  trip_id: String;
};
const getTripRecs = async (payload: GetTripRecs) => {
  const res = await ax.get(`/v3/trip/${payload.trip_id}/recs`).catch((e) => e.response);
  if (res.status !== 200) return [];
  return res.data;
};

interface GetTripSnaps {
  trip_id: String;
};
const getTripSnaps = async (payload: GetTripSnaps) => {
  const res = await ax.get(`/v3/trip/${payload.trip_id}/snaps`).catch((e) => e.response);
  if (res.status !== 200) return [];
  return res.data;
};

interface GetTripIncidents {
  trip_id: String;
};
const getTripIncidents = async (payload: GetTripIncidents) => {
  const res = await ax.get(`/v3/trip/${payload.trip_id}/incidents`).catch((e) => e.response);
  if (res.status !== 200) return [];
  return res.data;
};

interface UpdateTripDriver {
  trip_id: String;
  driver_id: String;
  state: String;
};
const updateTripDriver = async ({trip_id, driver_id, state}: UpdateTripDriver) => {
  const res = await ax.put(`/v3/trip/${trip_id}/driver`, {
    id: driver_id,
    state,
  }).catch((e) => e.response);
  if (res.status !== 200) return res.data;
  return true;
};

export default {
  getV3: getTripV3,
  route: getTripRoute,
  recs: getTripRecs,
  snapsV3: getTripSnaps,
  incidents: getTripIncidents,
  get: async (tripId) => {
    const res = await ax.get(`/trip/${tripId}`).catch((e) => e.response);
    if (res.status !== 200) return;
    return res.data;
  },
  coords: async (tripId, afterCoordId = 0, isTimeline) => {
    const res = await ax.get(`/trip/${tripId}/coords`, {
      params: {
        after_coord_id: afterCoordId,
        ...isTimeline && {is_timeline: true},
      },
    });
    return res.data;
  },
  extendedIncident: async (tripId, extendedIncidentId) => {
    const res = await ax.get(`/trip/${tripId}/extended-incident/${extendedIncidentId}`)
      .catch((e) => e.response);
    if (res.status !== 200) return null;
    return res.data;
  },
  driver: {
    put: async (tripId, driverId, state) => {
      await ax.put(`/trip/${tripId}/driver`, {id: driverId, state});
    },
    putV3: updateTripDriver,
  },
  assigner: async (tripId) => {
    const res = await ax.get(`/trip/${tripId}/assigner`);
    return res.data;
  },
  unhide: async (id) => {
    await ax.put(`/trip/${id}/unhide`);
  },
  snaps: async (id, cameraType) => {
    const res = await ax.get(`/trip/${id}/snaps/${cameraType}`).catch((e) => e.response);
    if (res.status !== 200) return [];
    return res.data;
  },
};
