import { ax } from './helpers';
import { origin } from '$consts';

interface UpdateRecordingDriverPayload {
  rec_id: String;
  driver_id: Number;
}
const updateRecordingDriver = async (payload: UpdateRecordingDriverPayload) => {
  const res = await ax.put(
    `/rec/${payload.rec_id}`,
    {
      driver_id: payload.driver_id,
    },
  ).catch((e) => e.response);
  return res.status === 200;
};

export default {
  get: async (recId) => {
    const res = await ax.get(`/video/${recId}`).catch((e) => e.response);
    if (res.status !== 200) return;
    return res.data;
  },
  link: (id) => `${origin.baseURL}/rec/${id}`,
  merged: {
    link: (id) => `${origin.baseURL}/rec/${id}/merged`,
  },
  thumbnail: {
    link: (id) => `${origin.baseURL}/rec/${id}/thumbnail`,
  },
  favorite: async (id) => ax.put(`rec/${id}/favorite`),
  status: async (ids) => {
    const res = await ax.post('/rec/status', ids);
    return res.data;
  },
  queue: async (ids) => ax.post('/rec/queue', ids),
  queuePrev: async (id) => ax.post(`/rec/${id}/queue/prev`),
  queueNext: async (id) => ax.post(`/rec/${id}/queue/next`),
  incident: {
    tag: async (recId, incidentName, incidentTag) => {
      const res = await ax.put(
        `/rec/${recId}/incident/${incidentName}/tag`,
        { tag: incidentTag },
      ).catch((e) => e.response);
      return res.status;
    },
    notFault: async (recId, underReview = false) => {
      const res = await ax.put(
        `/rec/${recId}/not-fault`,
        { value: underReview}
      ).catch((e) => e.response);
      return res.status === 200;
    },
  },
  annotation: {
    put: async (recId, incidents) => {
      const res = await ax.put(
        `/rec/${recId}/annotation`,
        { incidents },
      ).catch((e) => e.response);
      return res.status;
    },
    get: async (recId) => {
      const res = await ax.get(`/rec/${recId}/annotation`);
      return res.data;
    },
  },
  queueForTrip: async (tripId) => {
    const res = await ax.post(`/rec/queue-for-trip/${tripId}`);
    return res.data;
  },
  put: updateRecordingDriver,
};
