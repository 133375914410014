<template>
  <div
    class="flex flex-col max-w-max max-h-max relative"
    @touchstart="showTooltip"
    @touchend="hideTooltip"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
  >
    <slot name="default" />
    <div
      v-if="show && (value?.length || $slots.value)"
      class="fixed w-max text-black-400 shadow p-2 z-10 bg-gray-100 rounded"
      :style="{
        top: `${bound.top + bound.height + 10}px`,
      }"
    >
      <strong v-if="value?.length">{{ value }}</strong>
      <slot v-else-if="$slots.value" name="value" />
    </div>
  </div>
</template>

<script>
import Tooltip from './tooltip.vue';

export default {
  name: 'TooltipComponent',
  props: {
    value: String,
  },
  data() {
    return {
        show: false,
        element: null,
        bound: null,
    };
  },
  created() {
    window.addEventListener("resize", this.windowResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.windowResize);
  },
  methods: {
    showTooltip(e) {
        if (this.show) return;
        if (!this.element) {
          this.element = e.target;
          this.bound = this.element.getBoundingClientRect();
        }
        this.show = true;
    },
    hideTooltip() {
        if (!this.show) return;
        this.show = false;
    },
    windowResize(e) {
      if (!this.element) return;
      this.bound = this.element.getBoundingClientRect();
    },
  },
};
</script>