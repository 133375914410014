import {routeConfig} from '$utils';
import {priviledges} from '$consts';

export default [
  routeConfig('mobile/login', {
    auth: false,
    topRow: false,
  }, () => import('../views/mobile/Login.vue')),
  routeConfig('mobile/share', {
    topRow: false,
    ...priviledges.G,
  }, () => import('../views/mobile/Share.vue')),
  routeConfig('mobile/forgot-password', {
    auth: false,
    topRow: false,
  }, () => import('../views/mobile/ForgotPassword.vue')),
  routeConfig('mobile/forgot-password/set/:code', {
    auth: false,
    topRow: false,
  }, () => import('../views/mobile/ForgotPasswordSet.vue')),
];
