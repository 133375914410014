export default [
  {path: '/'},
  {
    path: '/:catchAll(.*)',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import('../views/NotFound.vue'),
  },
];
