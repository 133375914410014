import consts from '$consts';
import utils from '$utils';

import axios from 'axios';

const baseURL = consts.origin.baseURL;

export const ax = axios.create({
  baseURL,
  ...consts.mode !== 'mock' && {withCredentials: true},
  ...consts.mode === 'mock' && {
    headers: {
      'x-api-key': 'PMAK-63908756b2d97f0ecbff51d5-8c676ad514ddadf859bfb7df72baabc800',
    },
  },
});

utils.fixAxiosErrors(ax);

export const fActions = new Set();

ax.interceptors.request.use((config) => {
  if (config.method === 'get') {
    if (fActions.has(config.url)) {
      if (!config.params) config.params = {};
      config.params.meta = 'update';
    } else {
      fActions.add(config.url);
    }
  }
  return config;
});

export default {
  ax,
  fActions,
};
