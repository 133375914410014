<template>
  <!-- overlay -->
  <Teleport to="body">
    <div v-if="show" class="fixed inset-0 z-40" @click="show = false" />
  </Teleport>
  
  <div class="flex flex-row items-start relative">
    <!-- Trigger -->
    <fs-tooltip class="w-full min-w-[100%]">
      <template #content>
        triggerTooltip
      </template>
      <button
        v-if="$slots.trigger"
        :class="{
          [`flex flex-row items-center justify-center border-[1px]
          border-transparent transition-all ease-in-out font-semibold rounded
          hover:bg-gray-200 w-full !p-2 text-lg h-12 ${triggerClass ?? ''}`]: true,
          'bg-gray-100 cursor-not-allowed': triggerDisabled,
        }"
        @click="() => {
          if (triggerDisabled) return;
          show = !show;
        }"
      >
        <slot name="trigger" />
      </button>
    </fs-tooltip>

    <!-- Content -->
    <div
      :class="{
        [`
          flex flex-col absolute bg-white shadow overflow-hidden
          mt-14 z-50 min-w-[15rem] w-full rounded transition-all
          ease-in-out origin-top
          ${contentClass ?? ''}
        `]: true,
        'scale-y-100 opacity-1': show && $slots.content,
        'scale-y-0 opacity-0': !show || !$slots.content,
      }"
      @click="() => {
        if (!dismissOnClickContent) return;
        show = false;
      }"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import Tooltip from './tooltip.vue';

export default {
  name: 'DropdownComponent',
  components: {
    Tooltip,
  },
  props: {
    triggerDisabled: Boolean,
    triggerTooltip: String,
    triggerClass: Object|String,
    contentClass: String,
    dismissOnClickContent: Boolean,
  },
  data() {
    return {
      show: false,
    };
  },
};

</script> 