import {ax} from './helpers';
import {origin} from '$consts';

interface GetDeviceMagicV3 {
  magic_id: string;
};
const getDeviceMagicV3 = async (payload: GetDeviceMagicV3) => {
  const res = await ax.get(`/v3/magic/${payload?.magic_id}/device`).catch((e) => e.response);
  if (res.status !== 200) return;
  return res.data;
};

export default {
  create: async (kind, deviceId, hoursToExpiry) => {
    const now = (new Date()).getTime();
    const expiryAt = new Date(now + (1000 * 60 * 60 * hoursToExpiry));
    const res = await ax.post(`/magic/create/${kind}/${deviceId}`, {expiry_at: expiryAt});
    return res.data.magic;
  },
  delete: async (id) => {
    await ax.delete(`/magic/${id}`);
  },
  getDeviceMagicV3,
  device: {
    get: async (magic, afterCoordId = 0) => {
      const res = await ax.get(
        `/magic/${magic}/device`,
        {params: {after_coord_id: afterCoordId}},
      );
      return res.data;
    },
    snap: async (magic, snapId, camera) => {
      const res = await ax.get(
        `/magic/${magic}/device/snap/${snapId}/${camera}`,
        {responseType: 'arraybuffer'},
      );
      return res.data;
    },
  },
  fastSnaps: {
    status: async (magic) => {
      const res = await ax.get(`/magic/${magic}/fast-snaps/status`);
      return res.data;
    },
    start: async (magic) => {
      await ax.post(`/magic/${magic}/fast-snaps/start`);
    },
    snap: async (magic) => {
      const res = await ax.get(
        `/magic/${magic}/fast-snaps/snap`,
        {responseType: 'arraybuffer'},
      );
      return {
        id: res.headers['snap-id'],
        data: res.data,
      };
    },
  },
  incidentVideo: {
    link: (magic) => `${origin.baseURL}/magic/${magic}/incident-video`,
  },
  link(magic) {
    switch (magic.kind) {
      case 'device': return `${origin.baseURL}/#/map/v2/magic/${magic.magic}`;
      case 'fast-snaps': return `${origin.baseURL}/#/fast-snaps?${magic.magic}`;
      case 'incident-video': return `${origin.baseURL}/magic/${magic.magic}/incident-video`;
      case 'qcet': return `${origin.baseURL}/#/fast-snaps?${magic.magic}`;
      default: throw new Error(`No such magic kind "${magic.kind}".`);
    }
  },
};
