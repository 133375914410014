import {ax} from './helpers';

export default {
  get: async () => {
    const res = await ax.get('/annotation');
    return res.data;
  },
  management: async () => {
    const res = await ax.get('/annotation/management');
    return res.data;
  },
  take: async (kind, id) => {
    await ax.put(`/annotation/${kind}/${id}/take`);
  },
  untake: async (kind, id) => {
    await ax.put(`/annotation/${kind}/${id}/untake`);
  },
  complete: async (kind, id) => {
    await ax.put(`/annotation/${kind}/${id}/complete`);
  },
};
