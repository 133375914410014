<template>
  <Nav />
  <router-view :key="$route.path" />
  <!-- Appearance Selector: Admin -->
  <div
    v-if="$store.getters.showAdmin"
    class="flex flex-row items-center fixed z-[999999999] top-1/2 left-2 -translate-y-1/2 transition-all ease-in-out duration-300"
    :class="{
      '-translate-x-[115px]': !appearanceMenuShown,
    }"
  >
    <div class="flex flex-col items-start relative bg-purple-400 rounded-lg p-3">
      <span class="w-full text-center mb-2">✨</span>
      <div class="flex flex-col mb-2">
        <fs-radio
          v-model="appearance"
          value="light"
          label="Light"
        />
      </div>
      <div class="flex flex-col mb-2">
        <fs-radio
          v-model="appearance"
          value="dark"
          label="Dark"
        />
      </div>
      <div class="flex flex-col">
        <fs-radio
          v-model="appearance"
          value="system-preference"
          label="OS"
        />
      </div>
    </div>
    <div class="flex flex-row ml-2">
      <fs-button
        color="gray"
        type="rounded"
        size="small"
        class="mr-2"
        :icon="appearanceMenuShown ? 'chevron_left' : 'chevron_right'"
        @click="appearanceMenuShown = !appearanceMenuShown"
      />
    </div>
  </div>
</template>

<script>
import Nav from './nav.vue';
import {utils} from '$lib';

export default {
  components: {
    Nav,
  },
  data() {
    return {
      interval: null,
      appearanceMenuShown: false,
      appearance: localStorage.theme ?? 'system-preference',
    };
  },
  watch: {
    '$store.state.authenticated': function appWatchStoreAuthenticated() {
      const path = utils.redirect(this.$store);
      if (path) {
        this.$router.push(path);
      } else if (path === undefined) {
        this.$router.push(this.$store.state.route.fullPath);
      }
    },
    appearance: function pageWatchAppearance() {
      utils.toggleDarkMode(this.appearance);
    },
  },
  async created() {
    utils.toggleDarkMode(localStorage.theme ?? 'system-preference');
    const activityEvents = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart',
    ];
    for (const i of activityEvents) {
      document.addEventListener(
        i,
        () => {
          this.$store.state.active = true;
          document.body.style.opacity = '100%';
          this.active = true;
        },
        true
      );
    }
    this.interval = setInterval(this.checkActivity, 600e3);
    if (this.$store.state.magic) return;
    await this.$store.dispatch('checkAuthenticated');
  },
  methods: {
    checkActivity() {
      if (!this.active) {
        this.$store.state.active = false;
        document.body.style.opacity = '50%';
      }
      this.active = false;
    },
  },
};
</script>

<style>
body {
  @apply bg-slate-100 dark:!bg-slate-800;
}
</style>