import {ax} from './helpers';

interface GetDriverStatsPayload {
  driver_id: Number;
  from: Date;
  to: Date;
}
export const getDriverStats = async (payload: GetDriverStatsPayload) => {
  const from = payload.from.getTime() / 1000;
  const to = payload.to.getTime() / 1000;
  const res = await ax.get(`/coaching-message/driver/${
    payload?.driver_id
  }/stats?from=${
    from
  }&to=${to}`)
    .catch((e) => e.response);
  if (res.status !== 200) return;
  return res.data;
};

export default {
  post: async (driverId, recId, message, sendAt) => {
    await ax.post('/coaching-message', {
      driver_id: driverId,
      rec_id: recId,
      message,
      send_at: sendAt,
    });
  },
  put: async (id, message) => {
    await ax.put(`/coaching-message/${id}`, {message});
  },
  delete: async (id) => {
    await ax.delete(`/coaching-message/${id}`);
  },
  driver: async (driverId) => {
    const res = await ax.get(`/coaching-message/driver/${driverId}`)
      .catch((e) => e.response);
    if (res.status !== 200) return [];
    return res.data;
  },
  getDriverStats,
};
