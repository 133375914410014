import { ax } from './helpers';

export const getAPITokens = async () => {
    const res = await ax.get('/v3/api_token').catch((e) => e.response);
    if (res.status !== 200) return;
    return res.data;
};

interface GenerateAPIToken {
    fleet_ids: Array<Number>;
    permission_id: String;
    name: String;
}
export const generateAPIToken = async (payload: GenerateAPIToken) => {
    const res = await ax.post('/v3/api_token', payload).catch((e) => e.response);
    if (res.status !== 200) return;
    return res.data;
};

export const getAPITokensPermissions = async () => {
    const res = await ax.get('/v3/api_token/permission').catch((e) => e.response);
    if (res.status !== 200) return;
    return res.data;
};

interface RegenerateAPIToken {
    api_token_id: String;
}
export const regenerateAPIToken = async (payload: RegenerateAPIToken) => {
    const res = await ax.put(`/v3/api_token/${payload?.api_token_id}`).catch((e) => e.response);
    if (res.status !== 200) return;
    return res.data;
};

interface DeleteAPIToken {
    api_token_id: String;
}
export const deleteAPIToken = async (payload: DeleteAPIToken) => {
    const res = await ax.delete(`/v3/api_token/${payload?.api_token_id}`).catch((e) => e.response);
    return res.status === 200;
};

export default {
    get: getAPITokens,
    generate: generateAPIToken,
    regenerate: regenerateAPIToken,
    delete: deleteAPIToken,
    permissions: getAPITokensPermissions,
};
