import { origin } from '$consts';
import { ax } from './helpers';

export const getStitcherVideo = (id: Number) => `${origin.baseURL}/stitcher/${id}`;

export const getStitcherVideoThumbnail = (id: Number) => `${origin.baseURL}/stitcher/${id}/thumbnail`;

interface CreateNewStitchVideo {
    fleet_id: String;
    device_id: String;
    rec_ids: Array<String>;
}
export const createNewStitchVideo = async (payload: CreateNewStitchVideo) => {
    const res = await ax.post('/stitcher', payload);
    return res.status === 201;
};

export default {
    video: getStitcherVideo,
    thumbnail: getStitcherVideoThumbnail,
    post: createNewStitchVideo,
};
