import {ax} from './helpers';

export default {
  login: async (email, password) => ax.post(
    '/auth/login',
    {email, password},
  ).catch((e) => e.response),
  logout: async () => ax.post('/auth/logout'),
  signup: async (
    email,
    password,
    firstName,
    lastName,
    company,
    verifySecret,
  ) => ax.post('/auth/signup', {
    email,
    password,
    first_name: firstName,
    last_name: lastName,
    company,
    verify_secret: verifySecret,
  }).catch((e) => e.response),
  forgotPassword: {
    put: async (email, isMobile = false) => ax.put(
      '/auth/forgot-password',
      {email, isMobile},
    ).catch((e) => e.response),
    set: async (password, code) => ax.put(
      `/auth/forgot-password/set/${code}`,
      {password},
    ).catch((e) => e.response),
  },
  email: {
    invite: {
      post: async (email, fleetIds, driverId, groupId) => ax.post('/email/invite', {
        email,
        fleet_ids: fleetIds,
        driver_id: driverId,
        group_id: groupId,
      }),
      get: async () => {
        const res = await ax.get('/email/invite');
        return res.data;
      },
      put: async (email, fleetIds) => ax.put(`/email/invite/${email}`, {fleet_ids: fleetIds}),
      delete: async (email) => ax.delete(`/email/invite/${email}`),
    },
    resend: async (email) => ax.put('/email/resend', {
      email,
    }),
  },
};
