import { ax } from './helpers';

type ReportIdentificationFormat = 'distance'|'count'|'device_count';
interface GetFleetReportsIdentificationPayload {
  from: Number;
  to: Number;
  fleetId: Number;
  deviceIds: Array<String>;
  format: ReportIdentificationFormat;
};
export const getFleetReportsIdentification = async (payload: GetFleetReportsIdentificationPayload) => {
  const deviceIdsQuery = payload.deviceIds.reduce((a: String, i: String) => a + '&' + 'device_ids=' + i, '');
  const from = payload.from.getTime() / 1000;
  const to = payload.to.getTime() / 1000;
  const url = `/fleet/${
    payload.fleetId
  }/identification?start=${
    from
  }&end=${to}&format=${payload.format}${deviceIdsQuery}`;
  const res = await ax.get(url).catch((e) => e.response);
  if (res.status !== 200) return;
  return res.data;
};

interface UpdateFleetSettingsPayload {
  fleet_id: Number;
  speeding_rec_required: Boolean;
  speeding_percentage: Number;
  speeding_offset: Number;
  webhook_url: String;
  webhook_incident_ids: Array<Number>;
};
export const updateFleetSettings = async (payload: UpdateFleetSettingsPayload, webhook = false) => {
  const sanitized = webhook ?
  {
    webhook_url: payload?.webhook_url,
    webhook_incident_ids: payload?.webhook_incident_ids,
  } :
  {
    speeding_rec_required: payload?.speeding_rec_required,
    speeding_percentage: payload?.speeding_percentage,
    speeding_offset: payload?.speeding_offset,
  };
  const r = await ax.post(
    `/fleet/${payload.fleet_id}/setting`,
  sanitized,
  ).catch((e) => e.response);
  if (r.status !== 200) return r.data;
  return true;
};

interface GetFleetSettingsPayload {
  fleet_id: Number;
};
export const getFleetSettings = async (payload: GetFleetSettingsPayload) => {
  const r = await ax.get(`/fleet/${payload.fleet_id}/setting`).catch((e) => e.response);
  if (r.status !== 200) return;
  return r.data;
};

interface GetFleetDevicesPayload {
  fleet_id: Number;
  device_ids: Array<String>;
};
export const getFleetDevices = async (payload: GetFleetDevicesPayload) => {
  const deviceIdsQuery = payload.device_ids.reduce((a: String, i: String) => a + '&' + 'device_ids=' + i, '');
  const res = await ax.get(`/v3/fleet/${payload.fleet_id}/devices?${deviceIdsQuery}`);
  return res.data;
};

export default {
  post: async (name: any) => {
    await ax.post('/fleet', { name });
  },
  list: async () => {
    const res = await ax.get('/fleet/list');
    return res.data;
  },
  summary: async (fleetId: any) => {
    const res = await ax.get(`/fleet/${fleetId}/summary`);
    return res.data;
  },
  devices: getFleetDevices,
  detail: async (fleetId: any) => {
    const res = await ax.get(`/fleet/${fleetId}`);
    return res.data;
  },
  trips: async (
    fleetId: any,
    since: { getTime: () => number; },
    until: { getTime: () => number; },
    deviceIds: any,
    driverIds: any,
    incidents: any,
    includeUnassigned: any,
    offset: any,
    limit: any,
  ) => {
    const res = await ax.post(`/fleet/${fleetId}/trips`, {
      since: since.getTime() / 1000,
      until: until.getTime() / 1000,
      device_ids: deviceIds,
      driver_ids: driverIds,
      incidents,
      include_unassigned: includeUnassigned,
      offset,
      limit,
    });
    return res.data;
  },
  put: async (fleetId: any, name: any) => ax.put(`/fleet/${fleetId}`, { name }),
  policy: {
    get: async (fleetId: any) => {
      const res = await ax.get(`/fleet/${fleetId}/policy`);
      return res.data;
    },
    post: async (fleetId: any, name: any, number: any) => {
      const res = await ax.post(`/fleet/${fleetId}/policy`, {
        name,
        number,
      }).catch((e) => e.response);
      return res.data;
    },
  },
  drivers: {
    get: async (fleetId: any) => {
      const res = await ax.get(`/fleet/${fleetId}/drivers`);
      return res.data;
    },
  },
  driver: {
    regular: async (fleetId: any, name: any, snapId: any, licenseNumber: any, email: any, phoneNumber: any) => {
      const res = await ax.post(`/fleet/${fleetId}/driver`, {
        name,
        snap_id: snapId,
        license_number: licenseNumber,
        email,
        phone_number: phoneNumber,
      }).catch((e) => e.response);
      if (res.status !== 200) return res;
      return res.data;
    },
    annotator: async (snapId: any) => {
      const res = await ax.post('/fleet/driver', { snap_id: snapId }).catch((e) => e.response);
      if (res.status !== 200) return false;
      return res.data;
    },
  },
  redFlags: async (fleetId: any) => {
    const res = await ax.get(`/fleet/${fleetId}/red-flags`);
    return res.data;
  },
  settings: {
    get: getFleetSettings,
    put: updateFleetSettings,
  },
  videos: async (
    fleetId: any,
    since: { getTime: () => number; },
    until: { getTime: () => number; },
    deviceIds: any,
    driverIds: any,
    incidents: any,
    onlyIncidents: any,
    onlyGood: any,
    offset: any,
    limit: any,
    favoriteOnly: any,
  ) => {
    const res = await ax.post(`/fleet/${fleetId}/videos`, {
      since: since.getTime() / 1000,
      until: until.getTime() / 1000,
      device_ids: deviceIds,
      driver_ids: driverIds,
      incidents,
      offset,
      limit,
      ...onlyIncidents && { only_incidents: onlyIncidents },
      ...onlyGood && { only_good: onlyGood },
      favoriteOnly,
    }).catch((e) => e.response);
    if (res.status !== 200) return {};
    return res.data;
  },
  face: {
    unidentified: async (fleetId: any) => {
      const res = await ax.get(`/fleet/${fleetId}/face/unidentified`);
      return res.data;
    },
  },
  reports: {
    identification: getFleetReportsIdentification,
    geo: async (from: Date, to: Date, fleetId: any, driverIds: any[] = []) => {
      const res = await ax.get(`/fleet/${fleetId}/geo-reports`, {
        params: {
          start: from.getTime() / 1000,
          end: to.getTime() / 1000,
          driver_ids: `[${driverIds.join(',')}]`,
        },
      }).catch((e) => e.response);
      if (res.status !== 200 || !Object.keys(res.data)?.length) return;
      return res.data;
    },
  },
  score: {
    get: async (from: { getTime: () => number; }, to: { getTime: () => number; }, fleetIds: any[], driverIds = []) => {
      const res = await ax.get('/fleet/score', {
        params: {
          from: from.getTime() / 1000,
          to: to.getTime() / 1000,
          fleet_ids: `[${fleetIds.join(',')}]`,
          driver_ids: `[${driverIds.join(',')}]`,
        },
      }).catch((e) => e.response);
      if (res.status !== 200) return null;
      return res.data;
    },
    drivers: async (from: { getTime: () => number; }, to: { getTime: () => number; }, fleetIds: any[], driverIds = []) => {
      const res = await ax.get('/fleet/score/drivers', {
        params: {
          from: from.getTime() / 1000,
          to: to.getTime() / 1000,
          fleet_ids: `[${fleetIds.join(',')}]`,
          driver_ids: `[${driverIds.join(',')}]`,
        },
      }).catch((e) => e.response);
      if (res.status !== 200) return null;
      return res.data;
    },
  },
  global: {
    score: async (from: { getTime: () => number; }, to: { getTime: () => number; }) => {
      const res = await ax.get('/fleet/global/score', {
        params: {
          from: from.getTime() / 1000,
          to: to.getTime() / 1000,
        },
      }).catch((e) => e.response);
      if (res.status !== 200) return null;
      return res.data;
    },
  },
  oldScore: async (fleetId: any, since: { getTime: () => number; }, until: { getTime: () => number; }, consts: any) => {
    const res = await ax.post(`/fleet/${fleetId}/score`, {
      since: since.getTime() / 1000,
      until: until.getTime() / 1000,
      consts,
    });
    return res.data;
  },
  magic: {
    list: async (fleetId: any) => {
      const res = await ax.get(`/fleet/${fleetId}/magic/list`);
      return res.data;
    },
  },
  dice: async (fleetId: any, since: { getTime: () => number; }, until: { getTime: () => number; }) => {
    const res = await ax.get(`/fleet/${fleetId}/dice`, {
      params: {
        since: since.getTime() / 1000,
        until: until.getTime() / 1000,
      },
    });
    return res.data;
  },
  hos: async (fleetId: any) => {
    const res = await ax.get(`/fleet/${fleetId}/hos`);
    return res.data;
  },
  coachingMessage: {
    get: async (fleetId: any) => {
      const res = await ax.get(`/fleet/${fleetId}/coaching-message`)
        .catch((e) => e.response);
      if (res.status !== 200) return [];
      return res.data;
    },
    dashboard: async (fleetId: any, from: { getTime: () => number; }, to: { getTime: () => number; }, driverIds: any[], offset = 0, limit = 1000) => {
      const res = await ax.get(`/fleet/${fleetId}/coaching-message/dashboard`, {
        params: {
          from: from.getTime() / 1000,
          to: to.getTime() / 1000,
          driver_ids: `[${driverIds.join(',')}]`,
          offset,
          limit,
        },
      }).catch((e) => e.response);
      if (res.status !== 200) return [];
      return res.data;
    },
  },
  deviceTimelines: async (id: any, since: { getTime: () => number; }, until: { getTime: () => number; }) => {
    const res = await ax.get(`/fleet/${id}/device-timelines`, {
      params: {
        since: since.getTime() / 1000,
        until: until.getTime() / 1000,
      },
    });
    return res.data;
  },
  accidents: async (id: any) => {
    const res = await ax.get(`/fleet/${id}/accidents`);
    return res.data;
  },
};
