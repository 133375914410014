import {ax} from './helpers';

export default {
  health: {
    incident: async (granularity) => {
      const res = await ax.get('/admin/health/incident', {params: {granularity}});
      return res.data;
    },
    face: async (granularity) => {
      const res = await ax.get('/admin/health/face', {params: {granularity}});
      return res.data;
    },
  },
  simInfo: {
    history: async (deviceId) => {
      const res = await ax.get(`/admin/sim-info/${deviceId}/history`);
      return res.data;
    },
    latest: async () => {
      const res = await ax.get('/admin/sim-info/latest');
      return res.data;
    },
  },
  report: {
    list: async (since, until, exclude, only, deviceId) => {
      const res = await ax.post('/admin/report/list', {
        since: Math.floor(since.getTime() / 1000),
        until: Math.floor(until.getTime() / 1000),
        exclude,
        only,
        device_id: deviceId,
      });
      return res.data;
    },
  },
  log: {
    error: async () => {
      const res = await ax.get('/admin/log/error');
      return res.data;
    },
  },
  mimic: async (userId) => {
    await ax.post(`/admin/mimic/${userId}`);
  },
  qaBuddy: async (deviceId, entries) => {
    const res = await ax.post('/admin/qa-buddy', {
      device_id: deviceId,
      entries,
    });
    return res.data;
  },
  todo: {
    device: {
      getTurnRec: async (deviceId) => {
        await ax.post(`/admin/todo/device/${deviceId}/get-turn-rec`);
      },
    },
  },
};
