import {ax} from './helpers';

export default {
  post: async (name) => {
    await ax.post('/group', {name});
  },
  get: async () => {
    const res = await ax.get('/group');
    return res.data;
  },
};
