import { ax } from './helpers';

export const authenticated = async () => {
  const res = await ax.get('/user/current').catch((e) => e.response);
  if (res.status !== 200) return false;
  return res.data;
};

export const details = async (phoneNumber: String, prefs: any) => {
  ax.put('/user/details', {
    phone_number: phoneNumber,
    prefs,
  });
};

interface InvitePayload {
  email: string;
  role_id: number;
  fleet_ids: Array<number>;
  group_id: number;
}
export const inviteUser = async (payload: InvitePayload) => {
  const r = await ax.post('/user', payload)
    .catch((e) => e.response);
  return r.status === 200;
};

export const changeUserPassword = async (oldPassword: String, password: String) => {
  const r = await ax.put('/user/password', {
    oldPassword,
    password,
  }).catch((e) => e.response);
  if (r.status !== 200) return r;
  return true;
};

export const deleteUser = async (userId: Number) => {
  const r = await ax.delete(`/user/${userId}`).catch((e) => e.response);
  if (r.status !== 200) return r;
  return true;
};

export default {
  authenticated,
  details,
  invite: inviteUser,
  password: changeUserPassword,
  delete: deleteUser,
};
