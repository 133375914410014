<template>
  <Dropdown
    ref="picker"
    :trigger-disabled="disabled"
    content-class="min-w-[650px] min-h-[300px] p-2"
  >
    <template #trigger>
      <i class="material-icons text-2xl">date_range</i>
      <span class="ml-2">{{ triggerText }}</span>
    </template>

    <template #content>
      <div class="flex flex-row">
        <div class="flex flex-col mr-2">
          <button
            v-for="i in shortcuts"
            :type="i.name === shortcutName ? 'primary' : null"
            :disabled="i.name === shortcutName"
            class="p-2 font-semibold rounded transition-colors ease-in-out mb-2 first:mt-6 last:mb-0"
            :class="{
              'bg-sky-300': i.name === shortcutName,
              'hover:bg-gray-200': i.name !== shortcutName,
            }"
            @click.stop="selectShortcut(i.name)"
          >
            {{ shortcutDescription(i.name) }}
          </button>
        </div>

        <div class="flex flex-col flex-1 min-w-[200px] min-h-[300px] mr-2">
          From
          <vue-cal
            class="vuecal--date-picker vuecal--blue-them"
            xsmall
            hide-view-selector
            :time-step="0"
            active-view="month"
            :min-date="new Date(0)"
            :max-date="today"
            :selected-date="since"
            :disable-views="['week', 'day']"
            @cell-click="selectSince($event)"
          />
        </div>

        <div class="flex flex-col flex-1 min-w-[200px] min-h-[300px]">
          To
          <vue-cal
            class="vuecal--date-picker vuecal--blue-them"
            xsmall
            hide-view-selector
            :time-step="0"
            active-view="month"
            :min-date="new Date(0)"
            :max-date="today"
            :selected-date="until"
            :disable-views="['week', 'day']"
            @cell-click="selectUntil($event)"
          />
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>

import {utils} from '$lib';
import {Dropdown} from '$components';

import {
  addDays,
  differenceInDays,
  endOfDay,
  startOfDay,
  subDays,
} from 'date-fns';
import _ from 'lodash';
import VueCal from 'vue-cal';

import 'vue-cal/dist/vuecal.css';

const shortcuts = [
  {name: 'today', since: 1, until: 0},
  {name: 'yesterday', since: 2, until: 1},
  {name: 'last_7_days', since: 7, until: 0},
  {name: 'last_30_days', since: 30, until: 0},
  {name: 'last_90_days', since: 90, until: 0},
  {name: 'last_6_months', since: 180, until: 0},
];

const shortcutsByName = shortcuts.reduce((a, i) => ({...a, [i.name]: i}), {});

export default {
  name: 'DateRangeComponent',
  components: {Dropdown,VueCal},
  props: {
    limit: Number,
    unlimitAdmin: Boolean,
    disabled: Boolean,
    defaultShortcutName: String,
    from: Date,
    to: Date,
  },
  emits: ['update:range'],
  data() {
    return {
      today: startOfDay(new Date()),
      since: null,
      until: null,
      shortcutName: null,
    };
  },
  computed: {
    effectiveLimit() {
      if (this.$store.getters.showAdmin && this.unlimitAdmin) return null;
      return this.limit;
    },
    shortcuts() {
      return _.reduce(
        shortcuts,
        (a, i) => {
          if (!this.limit || i.since - i.until <= this.limit) {
            a.push(i);
          } else if (!this.effectiveLimit) {
            a.push({
              name: `✨${i.name}`,
              since: i.since,
              until: i.until,
            });
          }
          return a;
        },
        [],
      );
    },
    triggerText() {
      if (!this.since || !this.until) return null;
      if (this.shortcutName) return this.shortcutDescription(this.shortcutName);
      const since = utils.dateOnlyFromDate(this.since);
      const until = utils.dateOnlyFromDate(this.until);
      return `${since} to ${until}`;
    },
  },
  watch: {
    from: function compWatchFrom() {
      if (!this.from) return;
      this.since = this.from;
      this.resetShortcut();
    },
    to: function compWatchTo() {
      if (!this.to) return;
      this.until = this.to;
      this.resetShortcut();
    },
  },
  mounted() {
    if (this.defaultShortcutName) {
      this.selectShortcut(this.defaultShortcutName);
      this.emit();
    }
    // custom watch over $refs
    this.$watch(
      () => this.$refs.picker.show,
      (show) => {
        if (!show) this.emit();
      },
    );
  },
  methods: {
    resetShortcut() {
      const d = differenceInDays(this.until, this.since) + 1;
      const shortcut = shortcuts.find((i) => i.since === d);
      if (!shortcut) {
        this.shortcutName = null;
        this.until = addDays(this.since, this.limit - 1);
        return;
      }
      this.selectShortcut(shortcut.name);
    },
    selectSince(since) {
      this.since = since;
      if (this.until < since) {
        this.until = since;
      } else if (this.effectiveLimit && differenceInDays(this.until, since) >= this.limit) {
        this.until = addDays(since, this.limit - 1);
      }
      this.shortcutName = null;
    },
    selectUntil(until) {
      this.until = until;
      if (this.since > until) {
        this.since = until;
      } else if (this.effectiveLimit && differenceInDays(until, this.since) >= this.limit) {
        this.since = subDays(until, this.limit - 1);
      }
      this.shortcutName = null;
    },
    selectShortcut(name) {
      name = name.replace('✨', '');
      const shortcut = shortcutsByName[name];
      this.since = subDays(this.today, shortcut.since - 1);
      this.until = subDays(this.today, shortcut.until);
      this.shortcutName = name;
    },
    emit() {
      this.$emit('update:range', {since: this.since, until: endOfDay(this.until)});
    },
    shortcutDescription(name) {
      return _.capitalize(name).replace(/_/g, ' ');
    },
  },
};

</script>
