<template>
  <fs-wrapper
    v-if="$route.meta.topRow && !$store.state.magic"
    class="!flex-row !p-0 items-center min-h-[4rem] rounded-none dark:!bg-zinc-950 dark:!bg-opacity-20"
  >
    <div
      class="
        bg-[center_bottom] bg-no-repeat h-11 p-0 min-w-[100px] mr-8
        bg-[url(https://cdn.xmatik.ca/portal/img/logo/fs-black.svg)]
        dark:bg-[url('https://cdn.xmatik.ca/portal/img/logo/fs-white.svg')]
      "
    />

    <!-- desktop menu -->
    <div class="hidden md:flex flex-row items-center flex-1">
      <!-- other pages -->
      <div v-for="(i, key) in menu" :key="key" class="flex flex-col mr-2">
        <fs-dropdown v-if="i.itemsName.some((j) => userCanSee(j))" v-model="dropdowns[i.key]">
          <template #trigger>
            <fs-button
              :type="!i.itemsName.includes(currentPage) ? 'text' : ''"
              :color="i.itemsName.includes(currentPage) ? 'blue' : 'black'"
              icon="expand_more"
              icon-position="right"
              @click="dropdowns[i.key] = true"
            >
              {{ i.title }}
            </fs-button>
          </template>

          <template #default>
            <div v-for="item in i.items" :key="item.name" class="flex flex-col w-full mb-3 last:mb-0">
              <fs-button
                v-if="userCanSee(item.name)" :type="currentPage === item.name ? '' : 'text'"
                :color="currentPage === item.name ? 'blue' : 'black'" class="!justify-start" :href="`/#/${item.name}`"
                @click="dropdowns[i.key] = false"
              >
                <i v-if="item.asset" :class="`fs-${item.icon} text-2xl`" />
                <i v-else class="material-icons">{{ item.icon }}</i>
                <span class="ml-2">{{ item.title }}</span>
              </fs-button>
            </div>
          </template>
        </fs-dropdown>
      </div>

      <!-- admin page -->
      <fs-button
        v-if="userCanSee('admin')" :type="currentPage === 'admin' ? '' : 'text'"
        :color="currentPage === 'admin' ? 'blue' : 'black'" @click="$router.push(`/admin`);"
      >
        ✨Admin
      </fs-button>

      <!-- install -->
      <fs-button
        v-if="$store.state.user?.role_name === 'installer'" href="/#/install/wizard" type="text" color="black"
        icon="no_crash" class="ml-2"
      >
        Install
      </fs-button>

      <div class="flex-1" />

      <!-- settings -->
      <fs-button
        v-if="$store.getters.showAdmin || $store.getters.isRegularUser" href="/#/settings/incident-settings"
        type="text" color="black" icon="settings" class="mr-2"
      />

      <!-- profile -->
      <fs-button
        v-if="!$store.getters.isDriverUser" href="/#/profile/preferences" type="text" color="black" icon="person"
        class="mr-2"
      />

      <!-- logout -->
      <fs-button type="text" color="black" icon="logout" class="mr-2" @click="logout" />
    </div>

    <!-- mobile menu -->
    <div class="flex md:hidden flex-row flex-1">
      <div class="flex-1" />
      <!-- menu trigger -->
      <fs-button
        type="text" color="black" icon="menu" class="mr-2" @click="() => {
          show = !show;
          if (show) {
            disableScroll();
          } else {
            enableScroll();
          }
        }"
      />
    </div>
    <!-- menu content -->
    <fs-wrapper
      size="x-small" class="
        md:hidden fixed left-0 right-0 top-16 bottom-0 z-[999999]
        backdrop-blur-lg transition-all ease-in-out origin-top mt-2
      " :class="{
        'scale-y-100 opacity-1': show,
        'scale-y-0 opacity-0': !show,
    }"
    >
      <!-- other pages -->
      <div v-for="(i, key) in menu" :key="key" class="flex flex-col w-full">
        <fs-dropdown
          v-if="i.itemsName.some((j) => userCanSee(j))" v-model="dropdowns[i.key]"
          content-class="w-[calc(100%-1rem)]"
        >
          <template #trigger>
            <fs-button
              :color="i.itemsName.includes(currentPage) ? 'blue' : 'gray'"
              icon="expand_more"
              color="black"
              icon-position="right"
              class="w-full mb-2 last:mb-0" @click="dropdowns[i.key] = true"
            >
              {{ i.title }}
            </fs-button>
          </template>

          <template #default>
            <div v-for="item in i.items" :key="item.name" class="flex flex-col w-full mb-2 last:mb-0">
              <fs-button
                v-if="userCanSee(item.name)" :color="currentPage === item.name ? 'blue' : 'gray'"
                class="!justify-start" :href="`/#/${item.name}`" @click="
                  dropdowns[i.key] = false;
                  show = false;
                "
              >
                <i v-if="item.asset" :class="`fs-${item.icon} text-2xl`" />
                <i v-else class="material-icons">{{ item.icon }}</i>
                <span class="ml-2">{{ item.title }}</span>
              </fs-button>
            </div>
          </template>
        </fs-dropdown>
      </div>

      <!-- admin page -->
      <fs-button
        v-if="userCanSee('admin')" :color="currentPage === 'admin' ? 'blue' : 'gray'" @click="
          show = false;
          $router.push(`/admin`);
        "
      >
        ✨Admin
      </fs-button>

      <!-- install -->
      <fs-button
        v-if="$store.state.user?.role_name === 'installer'" href="/#/install/wizard" color="gray"
        icon="no_crash" class="mt-2" @click="show = false"
      >
        Install
      </fs-button>

      <div class="flex-1" />

      <!-- settings -->
      <fs-button
        v-if="$store.getters.showAdmin || $store.getters.isRegularUser" href="/#/settings/incident-settings"
        color="gray" icon="settings" class="mb-2" @click="show = false"
      >
        Settings
      </fs-button>

      <!-- profile -->
      <fs-button
        v-if="!$store.getters.isDriverUser" href="/#/profile/preferences" color="gray" icon="person"
        class="mb-2" @click="show = false"
      >
        Profile
      </fs-button>

      <!-- logout -->
      <fs-button
        color="gray"
        icon="logout"
        @click="() => {
          show = false;
          logout();
        }"
      >
        Logout
      </fs-button>
    </fs-wrapper>
  </fs-wrapper>
</template>

<script>
import {Dropdown} from '$components';
import {api} from '$lib';

const keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

const wheelOpt = {passive: false};
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

export default {
  components: {
    Dropdown,
  },
  data() {
    return {
      show: false,
      dropdowns: {
        analytics: false,
        view: false,
        team: false,
        communcation: false,
      }
    };
  },
  computed: {
    currentPage() {
      return this.$route.name?.toLowerCase();
    },
    menu() {
      const analyticsMenu = {
        key: 'analytics',
        title: 'Analytics',
        items: [],
      };
      if (this.$store.getters.isDriverUser) {
        analyticsMenu.items.push({
          title: 'Data Analytics',
          // TODO: change it to analytics after we removed old one
          name: 'analytics',
          icon: 'pie_chart',
        });
      } else {
        analyticsMenu.items.push({
          title: 'Data Analytics',
          // TODO: change it to analytics after we removed old one
          name: 'analytics/fleet-risk',
          icon: 'pie_chart',
        });
      }
      analyticsMenu.items.push({title: 'HOS', icon: 'departure_board'});

      const viewMenu = {
        key: 'view',
        title: 'View',
        items: [
          {title: 'Map', name: 'map/v2', icon: 'place'},
          {title: 'Trips', icon: 'route'},
          {title: 'Camera Timeline', name: 'camera-timeline', icon: 'view_timeline'},
          {title: 'Videos', icon: 'videocam'},
          {title: 'Devices', icon: 'directions_bus_filled'},
        ],
      };

      const teamMenu = {
        key: 'team',
        title: 'Team',
        items: [
          {
            // TODO: change it to drivers after we removed old one
            title: 'Drivers',
            name: 'drivers',
            icon: 'driver',
            asset: true,
          },
          {title: 'Users', icon: 'groups'},
          {
            title: 'Fleets',
            name: 'fleets',
            icon: 'fleet',
            asset: true,
          },
          {title: 'Policy', icon: 'local_police'},
        ],
      };

      const communcationMenu = {
        key: 'communication',
        title: 'Communication',
        items: [
          {title: 'Coaching', icon: 'support_agent'},
          {title: 'Share', icon: 'share'},
        ],
      };

      return [analyticsMenu, viewMenu, teamMenu, communcationMenu]
        .map((i) => {
          const items = i.items.map((item) => ({
            ...item,
            name: item.name || item.title.toLowerCase(),
          }));
          return {
            ...i,
            items,
            itemsName: items.map((item) => item.name),
          };
        });
    },
  },
  methods: {
    userCanSee(view) {
      if (this.$store.getters.showAdmin) return true;
      let allowedViews = [];
      if (this.$store.getters.isDriverUser) {
        allowedViews = [
          'map/v2',
          'videos',
          'analytics',
          'hos',
        ];
      } else if (this.$store.getters.showHeadAnnotator) {
        allowedViews = [
          'map/v2',
          'trips',
          'videos',
          'camera-timeline',
          'drivers',
          'drivers',
          'analytics',
          'analytics/fleet-risk',
          'devices',
          'share',
          'hos',
          'coaching',
        ];
      } else if (this.$store.getters.showAnnotator) {
        allowedViews = [
        ];
      } else if (this.$store.getters.isInsurerUser) {
        allowedViews = [
          'map/v2',
          'trips',
          'videos',
          'analytics',
          'analytics/fleet-risk',
          'devices',
          'hos',
          'coaching',
          'users',
          'fleets',
        ];
      } else if (this.$store.getters.isInstallerUser) {
        allowedViews = [];
      } else {
        allowedViews = [
          'map/v2',
          'trips',
          'videos',
          'camera-timeline',
          'drivers',
          'drivers',
          'analytics',
          'analytics',
          'analytics/fleet-risk',
          'devices',
          'share',
          'hos',
          'coaching',
          'users',
          'fleets',
          'policy',
        ];
      }
      return allowedViews.includes(view);
    },
    async logout() {
      await api.logout();
      await this.$store.dispatch('checkAuthenticated');
    },
    disableScroll() {
      window.addEventListener('DOMMouseScroll', preventDefault, false);
      window.addEventListener(wheelEvent, preventDefault, wheelOpt);
      window.addEventListener('touchmove', preventDefault, wheelOpt);
      window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    },
    enableScroll() {
      window.removeEventListener('DOMMouseScroll', preventDefault, false);
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
      window.removeEventListener('touchmove', preventDefault, wheelOpt);
      window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    },
  },
};
</script>
