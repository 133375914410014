import trip from './trip';
import auth from './auth';
import user from './user';
import fleet from './fleet';
import group from './group';
import policy from './policy';
import device from './device';
import driver from './driver';
import rec from './rec';
import stitcher from './stitcher';
import annotation from './annotation';
import magic from './magic';
import coachingMessage from './coaching-message';
import apiToken from './api-token';
import admin from './admin';
import misc from './misc';
import incident from './incident';

/* Put the funtion in api to mirror the path.

For example, if path is `PUT /device/:id/setting`,
then should be at `api.device.setting`.

If there is another path at that location, use method to disambiguate.

For example, if another path is `POST /device/:id/setting/new`,
then should be `api.device.setting.new` and previous is `api.device.setting.put`.
*/

const api = {
  ...auth,
  user,
  fleet,
  group,
  policy,
  device,
  driver,
  trip,
  rec,
  stitcher,
  annotation,
  magic,
  incident,
  coachingMessage,
  apiToken,
  admin,
  ...misc,
};

window.api = api;

export default api;
