import helpers, {ax} from './helpers';
import consts from '$consts';

interface GetSnapPayload {
  snap_id: String;
  camera_type: String;
};
export const getSnap = (
  payload: GetSnapPayload,
) => `${consts.origin.baseURL}/v3/snap/${payload.snap_id}/${payload.camera_type}?t=${Date.now()}`;

export default {
  v2: {
    dataRequest: {
      validate: async (deviceId, year, month) => {
        const res = await ax.get(`/v2/${deviceId}/${year}/${month}`)
          .catch((e) => e.response);
        return res.status === 200;
      },
      get: (
        deviceId,
        year,
        month,
      ) => `${consts.origin.baseURL}/v2/link/${deviceId}/${year}/${month}`,
    },
  },
  snapV3: getSnap,
  snap: async (snapId, camera) => {
    const res = await ax.get(`/snap/${snapId}/${camera}`, {responseType: 'arraybuffer'})
      .catch((e) => e.response);
    if (res.status !== 200) return;
    return res.data;
  },
  userActionReset: () => {
    helpers.fActions = new Set();
  },
};
