import {api, utils, consts} from '$lib';

import _ from 'lodash';

import {createStore} from 'vuex';

export default createStore({
  state: {
    authenticated: null,
    user: null,
    fleets: [],
    devicesChecklist: {},
    drivers: {},
    incidents: [],
    driversChecklist: {},
    fleetId: null,
    deviceId: null,
    magic: null,
    route: null,
    reservedRoute: null,
    active: true,
    install: {
      deviceId: null,
    },
    admin: {
      listening: false,
      code: '',
    },
    originRedirect: null,
    redirect: null,
  },
  getters: {
    unit(state) {
      return state.user.prefs['use-imperial'] ? 'mi' : 'km';
    },
    selectedFleet(state) {
      return state.fleets.filter((fleet) => fleet.id === state.fleetId);
    },
    userEmail(state) {
      return state.user && state.user.email;
    },
    isRegularUser(state) {
      return state.user?.role_name === 'fleet-manager';
    },
    isDriverUser(state) {
      return state.user?.role_name === 'driver';
    },
    isInsurerUser(state) {
      return state.user?.role_name === 'insurer';
    },
    isInstallerUser(state) {
      return state.user?.role_name === 'installer';
    },
    showAdmin(state) {
      return state.user && state.user.admin;
    },
    showAnnotator(state) {
      return state.user && (state.user.role.annotator || state.user.admin);
    },
    showHeadAnnotator(state) {
      return state.user && (state.user.role.head_annotator || state.user.admin);
    },
    isAnnotator(state) {
      return state.user && state.user.role.annotator;
    },
    fleetsById(state) {
      return _.mapKeys(state.fleets, 'id');
    },
    fleetWithId(state, getters) {
      return (fleetId) => getters.fleetsById[fleetId];
    },
    deviceWithId(state) {
      return (deviceId) => state.devicesChecklist[deviceId];
    },
    devicesByDriver(state) {
      return _.mapKeys(state.devicesChecklist, (i) => i.driver_id);
    },
    isEveryDeviceSelected(state, getters) {
      if (getters.isDriverUser) return true;
      return Object.values(state.devicesChecklist).every((i) => i.value);
    },
    selectedDeviceIds(state) {
      return _.filter(state.devicesChecklist, (i) => i.value)
        .map((i) => i.id);
    },
    driverWithId(state) {
      return (driverId) => state.drivers[driverId];
    },
    driversById(state) {
      return state.driversChecklist;
    },
    isDriverSelected(state, getters) {
      return (driverId) => {
        if (getters.isDriverUser) return driverId === state.user.driver_id;
        if (getters.isEveryDriverSelected) return true;
        const driver = state.driversChecklist[driverId];
        return driver && driver.value;
      };
    },
    isEveryDriverSelected(state, getters) {
      if (getters.isDriverUser) return true;
      return Object.values(state.driversChecklist).every((i) => i.value);
    },
    selectedDriverIds(state, getters) {
      if (getters.isDriverUser) {
        return [state.user.driver_id, null];
      }
      if (getters.isEveryDriverSelected) {
        return [
          ..._.map(state.drivers, (driver) => driver.id),
          null,
        ];
      }
      return _.map(
        _.filter(
          state.driversChecklist,
          (i) => i.value,
        ),
        (i) => i.id,
      );
    },
    allIncidentsByName(state) {
      return Object.fromEntries(state.incidents.map((i) => [i.name, i]));
    },
    incidents(state) {
      return state.incidents.filter((i) => !i.extended);
    },
    incidentsByName(_state, getters) {
      return Object.fromEntries(getters.incidents.map((i) => [i.name, i]));
    },
    extendedIncidents(state) {
      return state.incidents.filter((i) => i.extended);
    },
    extendedIncidentsByName(_state, getters) {
      return Object.fromEntries(getters.extendedIncidents.map((i) => [i.name, i]));
    },
  },
  mutations: {
    setReservedRoute(state, route) {
      state.reservedRoute = route;
    },
    authenticate(state, user) {
      state.authenticated = true;
      this.commit('setUser', user);
      state.user = user;
      if (user.admin && !state.admin.listening) {
        document.addEventListener('keydown', (e) => {
          state.admin.code += `${e.key}d`;
        });
        document.addEventListener('keyup', (e) => {
          state.admin.code += `${e.key}u`;
          while (state.admin.code.length > 6) {
            state.admin.code = state.admin.code.slice(2);
          }
          const href = {
            /* eslint-disable quote-props */
            'addddu': '/#/admin',
            /* eslint-enable quote-props */
          }[state.admin.code];
          if (href) {
            state.admin.code = '';
            window.location.href = href;
          }
        });
        state.admin.listening = true;
      }
    },
    deauthenticate(state) {
      state.authenticated = false;
      state.user = null;
    },
    setFleets(state, fleets) {
      state.fleets = fleets;
      if (!state.fleetId) {
        if (state.user && state.user.driver_id) {
          state.fleetId = 'all';
        } else if (state.fleets.length > 0) {
          state.fleetId = _.sortBy(state.fleets, 'name')[0].id;
        }
      }
    },
    setDevices(state, devices) {
      state.devicesChecklist = Object.fromEntries(
        _.orderBy(devices, [(i) => i.name && i.name.toLowerCase(), 'id']).map((i) => [
          i.id,
          {...i, desc: utils.deviceDesc(i, this), value: true},
        ]),
      );
    },
    setIncidents(state, incidents) {
      state.incidents = incidents.filter((i) => !['construction_zones'].includes(i.name));
    },
    setDrivers(state, drivers) {
      const entries = drivers.map((i) => [i.id, i]);
      state.drivers = Object.fromEntries(entries);
      state.driversChecklist = Object.fromEntries(
        entries
          .filter(([_, driver]) => !driver.deleted_at)
          .map(([id, driver]) => [
            id,
            {
              ...driver,
              ...driver.has_image && {
                profile: `${consts.origin.blob}/driver-imgs/${id}/original.jpg?t=${Date.now()}`,
              },
              value: true,
            },
          ]),
      );
    },
    setUser(state, user) {
      state.user = user;
    },
    setOriginRedirect(state, origin) {
      state.originRedirect = origin;
    },
    setRedirect(state, url) {
      state.redirect = url;
    },
  },
  actions: {
    async updateFleets(context) {
      context.commit('setFleets', await api.fleet.list());
    },
    async checkAuthenticated(context) {
      const user = await api.user.authenticated();
      if (user) {
        context.commit('authenticate', user);
        await context.dispatch('updateIncidents');
        await context.dispatch('updateFleets');
        await context.dispatch('updateDevices');
        await context.dispatch('updateDrivers');
      } else {
        context.commit('deauthenticate');
      }
    },
    async updateDevices(context, {detail} = {}) {
      if (context.getters.isDriverUser) return;
      if (!context.state.fleetId) return;
      const fleet = detail
        ? await api.fleet.detail(context.state.fleetId)
        : await api.fleet.summary(context.state.fleetId);
      context.commit('setDevices', fleet.devices);
    },
    async updateDrivers(context) {
      if (!context.state.fleetId) return;
      context.commit(
        'setDrivers',
        await api.fleet.drivers.get(context.state.fleetId),
      );
    },
    async updateIncidents(context) {
      context.commit(
        'setIncidents',
        await api.incident.get(),
      );
    },
  },
});
