import {ax} from './helpers';

interface GetDriverInfoPayload {
  driver_id: Number;
}
const getDriverInfo = async (payload: GetDriverInfoPayload) => {
  const res = await ax.get(`/v3/driver/${payload?.driver_id}`)
    .catch((e) => e.response);
  if (res.status !== 200) return;
  return res.data;
};

interface GetDriverTripsPayload {
  driver_id: Number;
  offset: Number;
  limit: Number;
  until: Number;
}
const getDriverTrips = async (payload: GetDriverTripsPayload) => {
  const res = await ax.get(`/v3/driver/${
    payload.driver_id
  }/trips?until=${payload.until}&offset=${payload.offset}&limit=${payload.limit}`)
  .catch((e) => e.response);
  if (res.status !== 200) return;
  return res.data;
};

export default {
  get: getDriverInfo,
  tripsV3: getDriverTrips,
  regular: async (id, name, snapId, licenseNumber, email, status, phoneNumber) => {
    const r = await ax.put(`/driver/${id}`, {
      name,
      snap_id: snapId,
      license_number: licenseNumber,
      email,
      status,
      phone_number: phoneNumber,
    }).catch((e) => e.response);
    if (r.status !== 200) return r;
    return r.data;
  },
  merge: async (sourceDriverId, targetDriverId) => {
    const r = await ax.post('/driver/merge', {
      source_driver_id: sourceDriverId,
      target_driver_id: targetDriverId,
    }).catch((e) => e.response);
    return r.status === 200;
  },
  image: async (id, image) => {
    const fd = new FormData();
    fd.append('image', image);
    await ax({
      url: `/driver/${id}/image`,
      method: 'POST',
      data: fd,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  annotator: async (id, snapId) => {
    const r = await ax.put(`/driver/${id}/annotator`, {
      snap_id: snapId,
    }).catch((e) => e.response);
    return r.status === 200;
  },
  delete: async (id) => ax.delete(`/driver/${id}`),
  trips: async (options = {}) => {
    const res = await ax.get('/driver/current/trips', {
      params: {
        since: options.since && options.since.getTime() / 1000,
        until: options.until && options.until.getTime() / 1000,
        offset: options.offset,
        limit: options.limit,
      },
    });
    return res.data;
  },
  snaps: async (id) => {
    const res = await ax.get(`/driver/${id}/snaps`).catch((e) => e.response);
    if (res.status !== 200) return [];
    return res.data;
  },
};
