export const trip = {
  state: {
    unknown: 0,
    identified: 1,
    unidentifiable: 2,
    covered: 3,
  },
};

export const date = {
  second: 1e3,
  minute: 60 * 1e3,
  hour: 60 * 60 * 1e3,
  day: 24 * 60 * 60 * 1e3,
};

export const incidents = {
  follow_too_close: {
    description: 'Follow Too Close',
    color: '#fdfd6b',
  },
  late_start: {
    description: 'Late Start',
    color: '#c1e57e',
  },
  late_brake: {
    description: 'Late Brake',
    color: '#cc1872',
  },
  lane_change_no_signal: {
    description: 'Lane Violation',
    color: '#82d0fd',
  },
  hard_on_brake: {
    description: 'Hard On Brake',
    color: '#ed9365',
  },
  hard_on_gas: {
    description: 'Hard On Gas',
    color: '#7da045',
  },
  harsh_motion: {
    description: 'Harsh Motion',
    color: '#585858',
  },
  red_pass: {
    description: 'Red Light Violation',
    color: '#f769ac',
  },
  stop_sign_pass: {
    description: 'Stop Sign Violation',
    color: '#310000',
  },
  // button: {
  //   description: 'Button',
  //   color: '#585858',
  // },
  distracted_driving: {
    description: 'Distracted Driving',
    color: '#7b0202',
    annotationOnly: true,
  },
  zig_zagging: {
    description: 'Zig-Zagging',
    color: '#002ba0',
    annotationOnly: true,
  },
  hard_turning: {
    description: 'Hard Turning',
    color: '#1ba9c8',
    annotationOnly: true,
  },
  accident: {
    description: 'Accident',
    color: '#f20000',
    annotationOnly: true,
  },
  accident_no_fault: {
    description: 'Accident No Fault',
    color: '#f20000',
    annotationOnly: true,
  },
  red_flag: {
    description: 'Red-Flag',
    color: '#B85047',
    skip_influences_risk_score: true,
    annotationOnly: true,
  },
};

export const extendedIncidents = {
  no_seatbelt: {
    description: 'No Seatbelt',
    color: '#b2761f',
    hide: true,
  },
  speeding: {
    description: 'Speeding',
    color: '#5f0cb3',
  },
};

export const miscRisks = {
  over_driving: {
    description: 'over driving',
    color: '#c9f8cf',
    hidden: true,
  },
};

export const allRisks = {
  ...incidents,
  ...extendedIncidents,
  ...miscRisks,
};

export const expiryOptions = [
  {name: '1 Hour', value: 1},
  {name: '12 Hours', value: 12},
  {name: '1 Day', value: 24},
  {name: '2 Days', value: 48},
  {name: '1 Week', value: 168},
  {name: '1 Month', value: 720},
];

export const color = {
  map: {
    device: {
      online: '#80f711',
      badGps: '#F8C85C',
      offline: '#000000',
      offlineBadGps: '#939598',
      selected: '#71C3EE',
      border: '#FFFFFF',
    },
    trip: '#000000',
    snap: {
      outline: '#F5BA87',
      fill: '#FFFFFF',
      hover: '#71C3EE',
    },
    incident: {
      outline: '#000000',
      hover: '#71C3EE',
    },
    rec: [
      '#71C3EE',
      '#1AFFEB',
    ],
    range: {
      outline: '#B85047',
      fill: '#FFFFFF',
    },
  },
  map_v3: {
    device: {
      online: '#7dd3fc',
      badGps: '#fde047',
      offline: '#000000',
      offlineBadGps: '#d1d5db',
      selected: '#38bdf8',
      border: '#FFFFFF',
    },
    trip: '#000000',
    snap: {
      outline: '#F5BA87',
      fill: '#FFFFFF',
      hover: '#71C3EE',
    },
    incident: {
      outline: '#000000',
      hover: '#71C3EE',
    },
    range: {
      outline: '#B85047',
      fill: '#FFFFFF',
    },
  },
};

export const mapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  styles: [
    {
      "featureType": 'all',
      "elementType": 'labels.icon',
      "stylers": [
        {
          "visibility": 'off',
        },
      ],
    },
  ],
};

export const darkMapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  styles: [
    {
      "featureType": 'all',
      "elementType": 'labels.icon',
      "stylers": [
        {
          "visibility": 'off',
        },
      ],
    },
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#242f3e"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#746855"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#242f3e"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#d59563"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#d59563"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#263c3f"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#6b9a76"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#38414e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#212a37"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9ca5b3"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#746855"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#1f2835"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#f3d19c"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#2f3948"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#d59563"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#17263c"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#515c6d"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#17263c"
        }
      ]
    }
  ],
};

export const timelineOptions = {
  styles: [{elementType: 'all', stylers: [{visibility: 'off'}]}],
  minZoom: 18,
  maxZoom: 24,
  streetViewControl: false,
  mapTypeControl: false,
  clickableIcons: false,
  restriction: {
    latLngBounds: {
      north: 85,
      south: -85,
      west: 0,
      east: 360,
    },
  },
};

const prodBaseUrl = () => {
  const hostnames = [
    'access.foresightanalytics.ca',
    'portal.foresightanalytics.ca',
  ];
  if (hostnames.includes(window.location.hostname)) {
    return `https://${window.location.hostname}`;
  }
  return 'https://portal2-prod.droplet.xmatik.ca';
};
export const mode = import.meta.env.MODE;
export const baseURL = {
  mock: 'https://cb00f451-d569-45f8-bae5-b9f198ddbe4a.mock.pstmn.io',
  development: import.meta.env.VITE_APP_BACKEND_URL || 'https://portal2-staging.droplet.xmatik.ca',
  staging: 'https://portal2-staging.droplet.xmatik.ca',
  production: prodBaseUrl(),
}[mode];

export const origin = {
  baseURL,
  public: {
    mock: 'http://v2.foresightanalytics.ca',
    development: 'http://v2.foresightanalytics.ca',
    staging: 'http://v2.foresightanalytics.ca',
    production: 'https://foresightanalytics.ca',
  }[mode],
  publicCDN: {
    mock: 'https://cdn.xmatik.ca/public-website/img/setup/staging',
    development: 'https://cdn.xmatik.ca/public-website/img/setup/staging',
    staging: 'https://cdn.xmatik.ca/public-website/img/setup/staging',
    production: 'https://cdn.xmatik.ca/public-website/img/setup/production',
  }[mode],
  blob: {
    mock: 'https://foresightanalyticsstg.blob.core.windows.net',
    development: 'https://foresightanalyticsstg.blob.core.windows.net',
    staging: 'https://foresightanalyticsstg.blob.core.windows.net',
    production: 'https://foresightanalyticsprod.blob.core.windows.net',
  }[mode],
};

// these priviledges will hide contents from the roles they have in their self
export const priviledges = {
  A: {excludes: ['annotator', 'driver', 'fleet-manager', 'insurer', 'head-annotator', 'installer']},
  B: {excludes: ['annotator', 'driver', 'head-annotator', 'installer']},
  C: {excludes: ['driver', 'fleet-manager', 'installer']},
  D: {excludes: ['annotator', 'driver', 'installer']},
  E: {excludes: ['annotator', 'installer']},
  F: {excludes: ['annotator', 'insurer', 'installer']},
  G: {excludes: ['annotator', 'driver', 'insurer', 'installer']},
  H: {excludes: ['annotator']},
  I: {excludes: ['annotator', 'driver', 'insurer']},
  J: {excludes: ['annotator', 'driver']},
};

export const quartile = {
  // Green
  1: {
    color: '#86efac',
  },
  // Blue
  2: {
    color: '#93c5fd',
  },
  // Yellow
  3: {
    color: '#fde047',
  },
  // Red
  4: {
    color: '#fca5a5',
  },
};

export default {
  mode,
  trip,
  date,
  incidents,
  extendedIncidents,
  miscRisks,
  allRisks,
  expiryOptions,
  color,
  mapOptions,
  darkMapOptions,
  timelineOptions,
  origin,
  priviledges,
  quartile,
};
