import {routeConfig} from '$utils';

export default [
  {
    name: 'Login',
    path: '/login',
    component: () => import('../views/login/page.vue'),
    meta: {
      auth: false,
      topRow: false,
      title: 'Login',
    },
  },
  {
    name: 'Signup',
    path: '/signup',
    component: () => import('../views/signup/page.vue'),
    meta: {
      auth: false,
      topRow: false,
      title: 'Signup',
    },
  },
  {
    name: 'ForgotPassword',
    path: '/forgot-password',
    component: () => import('../views/forgot-password/page.vue'),
    meta: {
      auth: false,
      topRow: false,
      title: 'Forgot Password',
    },
  },
  {
    name: 'ForgotPasswordSet',
    path: '/forgot-password/set/:code',
    component: () => import('../views/forgot-password-set/page.vue'),
    meta: {
      auth: false,
      topRow: false,
      title: 'Forgot Password Set',
    },
  },
];
