import {ax} from './helpers';

export default {
  drivers: async (policyId, deviceIds, driverIds) => {
    const res = await ax.put(`/policy/${policyId}/drivers`, {
      device_ids: deviceIds,
      driver_ids: driverIds,
    }).catch((e) => e.response);
    if (res.status === 200) return null;
    if (!res.data) throw new Error(`Got bad status ${res.status} but no error.`);
    return res.data;
  },
  details: async (policyId, name, fleetId, number) => {
    const res = await ax.put(`/policy/${policyId}/details`, {
      name,
      number,
      fleet_id: fleetId,
    }).catch((e) => e.response);
    if (res.status === 200) return null;
    if (!res.data) throw new Error(`Got bad status ${res.status} but no error.`);
    return res.data;
  },
  delete: (policyId) => ax.delete(`/policy/${policyId}`),
};
