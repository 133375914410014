import api from '$api';
import store from '../store';
import utils from '$utils';

import {createRouter, createWebHashHistory} from 'vue-router';

import _ from 'lodash';

import RegularRoutes from './regular.ts';
import MagicRoutes from './magic.ts';
import AdminRoutes from './admin.ts';
import AuthRoutes from './auth.ts';
import InstallRoutes from './install.ts';
import MobileRoutes from './mobile.ts';
import MiscRoutes from './misc.ts';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    ...RegularRoutes,
    ...MagicRoutes,
    ...AdminRoutes,
    ...AuthRoutes,
    ...InstallRoutes,
    ...MobileRoutes,
    ...MiscRoutes,
  ],
});

window.view = (name) => {
  if (!name) return router.getRoutes();
  const route = router
    .getRoutes()
    .filter((i) => i.name === name);
  if (route.length < 1) return 'no such route';
  return route[0].instances.default;
};

router.beforeEach((to, from, next) => {
  api.userActionReset();
  store.state.magic = utils.hashMagic();
  store.state.route = to; // `this.$route` doesn't seem to be accurate always
  const prefix = to.meta.title ? `${to.meta.title} | ` : '';
  document.title = `${prefix} Portal - Foresight`;
  next(utils.redirect(store));
});

export default router;
